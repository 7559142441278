import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { Globals } from '../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.scss']
})
export class EarningsComponent implements OnInit, OnDestroy {

  @ViewChild('alertEarnings', { read: ViewContainerRef }) alertEarnings: ViewContainerRef;

  rows = [];
  columns = [
    { prop: 'count', name: '#', width: 30 },
    { prop: 'sacco', name: 'sacco'},
    { prop: 'onboarded', name: 'Onboarded On' },
    { prop: 'amount', name: 'Amount Earned' },
  ];

  subscriptions: Subscription[] = [];
  loading_data: boolean = false;
  disable_payment_processing: boolean = false;
  sending_request: boolean = false;
  no_earnings: boolean = false;
  response:any = [];
  total_amount: String = '';
  my_payment_options: any [];
  selected_method: any = '';

  constructor(
    private _title: Title,
    private _data: DataService,
    public globals: Globals,
    private _toastr: ToastrService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver
  ) {
    this._title.setTitle('Earnings');
    this.loading_data = true;
    this._data.get_partner_earnings().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 1) {
          this.no_earnings = false;
          this.rows = this.response.earnings;
          this.total_amount = this.response.total_amount;
        }else{
          this.no_earnings = true;
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
        this.get_payment_methods();
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      ()=>{
        this.loading_data = false;
      }
    );
  }

  get_payment_methods(){
    this.disable_payment_processing = true;
    this._data.get_payment_methods().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 1) {
          this.my_payment_options = this.response.accounts;
          if(this.my_payment_options.length){
            this.selected_method = this.my_payment_options[0].id;
          }
          console.log(this.my_payment_options);
        }else{
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      ()=>{
        this.disable_payment_processing = false;
      }
    );
  }

  request_payment(id: any){
    if(id != ''){
      this.sending_request = true;
      this._data.request_withdrawal(id, 200).subscribe(
        data => {
          this.response = data;
          console.log(this.response);
          if (this.response.result_code == 1) {
            this._alertService.success('Success: You\'ve successfully requested for a withdrawal. After processing, the money will be deposited to the selected payment account, if successful. This can take up to 5 working days.');
          }if (this.response.result_code == 0){
            this._alertService.error('You currently don\'t have enough money to make a withdrawal request. Please try again later on, after you\'ve made enough money.');
          }else{
            this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
          }
        },
        error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
        },
        ()=>{
          this.sending_request = false;
        }
      );
    }
  }

  ngOnInit() {
    this.showAlert('alertEarnings');
  }

  ngOnDestroy(){
    
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
